angular.module('cpformplastApp')
  .directive('besearchtable', () => ({
    templateUrl: 'components/be-search-table/be-search-table.html',
    restrict: 'E',
    controller: 'BeSearchTableController',
    controllerAs: 'besearchtable',
    scope: {
      tableStructure: '=tableStructure',
      objects: '=objects',
      buttons: '=buttons',
      rowClick: '=rowClick',
      onDataChanged: '=onDataChanged',
      archiveMode: '=archiveMode'
    }
  }));
